import { SwiperOptions } from 'swiper'
import Swiper, { Navigation, Pagination } from 'npm/swiper'
import paginationSet from 'js/features/paginationLayout'

export default function (parentSelector: string, swiperOptions: SwiperOptions) {
	const parentEl = document.querySelector<HTMLElement>(parentSelector)
	if (!parentEl) return

	const modules = swiperOptions.modules
	  ? [ Navigation, Pagination, ...swiperOptions.modules ]
	  : [ Navigation, Pagination ]

	new Swiper(`${ parentSelector } .swiper`, {
		...swiperOptions,
		navigation: {
			prevEl: `${ parentSelector } .arrow-btn_prev`,
			nextEl: `${ parentSelector } .arrow-btn_next`,
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'fraction',
		},
		on: {
			init: (swiper) => paginationSet(swiper, parentEl),
			resize: (swiper) => paginationSet(swiper, parentEl),
			transitionStart: (swiper) => paginationSet(swiper, parentEl),
		},
		modules,
	})
}