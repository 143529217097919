import createPaginationSwiper from 'js/features/swipers/createPaginationSwiper'

createPaginationSwiper('.blog', {
	spaceBetween: 20,
	slidesPerView: 1,
	slidesPerGroup: 1,
	observer: true,
	breakpoints: {
		380: {
			spaceBetween: 15,
			slidesPerView: 1,
			slidesPerGroup: 1,
		},
		601: {
			spaceBetween: 30,
			slidesPerView: 1,
			slidesPerGroup: 1,
		},
		801: {
			spaceBetween: 40,
			slidesPerView: 2,
			slidesPerGroup: 2,
		},
		1101: {
			slidesPerView: 1,
			slidesPerGroup: 1,
		}
	},
})