import createPaginationSwiper from 'js/features/swipers/createPaginationSwiper'

createPaginationSwiper('.catalog', {
	spaceBetween: 40,
	slidesPerView: 'auto',
	slidesPerGroup: 1,
	breakpoints: {
		730: {
			slidesPerGroup: 2,
		},
		1085: {
			slidesPerGroup: 3,
		},
		1450: {
			slidesPerGroup: 4,
		}
	},
})