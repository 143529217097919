// TODO: Refactor whole file

export interface IMapItem {
	el: HTMLElement,
	placemark: ymaps.Placemark,
}

let needHideHover: boolean = true

export function createMapList(map: ymaps.Map, list: IMapItem[]) {
	list.forEach(mapItemObj => {
		mapItemObj.el.addEventListener('click', () => zoomMapToPlacemark(map, mapItemObj.placemark))

		const mobileInfoTel = mapItemObj.el.querySelector<HTMLElement>('.map-item__mobile-info .map-item__tel')
		const mobileInfoMail = mapItemObj.el.querySelector<HTMLElement>('.map-item__mobile-info .map-item__mail')

		if (!mobileInfoTel || !mobileInfoMail) return

		mobileInfoTel.innerText = String(mapItemObj.el.dataset.tel)
		mobileInfoMail.innerText = String(mapItemObj.el.dataset.mail)

		const contactBtnsSelector = '.map-item__tel-btn, .map-item__mail-btn'
		const contactBtns = mapItemObj.el.querySelectorAll<HTMLElement>(contactBtnsSelector)

		if (window.matchMedia('(max-width: 1100px)').matches) {
			contactBtns.forEach(btn => {
				btn.addEventListener('click', () => openMobileInfo(mapItemObj.el))
			})
		} else {
			contactBtns.forEach(btn => {
				btn.addEventListener('mouseenter', (e) => hoverHandler(e, mapItemObj.el))
				btn.addEventListener('mouseout', hideHoverEl)
			})
		}

	})
}

function openMobileInfo(mapItem: HTMLElement) {
	const mobileInfoEl = mapItem.querySelector('.map-item__mobile-info')
	if (!mobileInfoEl) return

	mobileInfoEl.classList.toggle('opened')
}

function hideHoverEl() {
	needHideHover = true

	setTimeout(() => {
		if (!needHideHover) return

		let hoverEl = document.querySelector<HTMLElement>('.map-hover.active')
		if (hoverEl) hoverEl.classList.remove('active')
	}, 100)
}

async function zoomMapToPlacemark(map: ymaps.Map, object: ymaps.Placemark) {
	if (!object.geometry) return
	const bounds = object.geometry.getBounds()

	if (!bounds) return

	await map.setBounds(bounds)
	await map.setZoom(17)
}

const mapBlock = document.querySelector<HTMLElement>('.map-block')

function hoverHandler(e: MouseEvent, listItem: HTMLElement) {
	const eventTarget = e.currentTarget as HTMLElement
	if (!mapBlock) return

	const rect = eventTarget.getBoundingClientRect()
	const isTelBtn = eventTarget.classList.contains('map-item__tel-btn')

	const
	  title = String(listItem.dataset.name),
	  text = isTelBtn ? String(listItem.dataset.tel) : String(listItem.dataset.mail)

	const hoverEl = createMapHoverEl({ title, text }, mapBlock)
	if (!hoverEl) return

	hoverEl.style.top = rect.top - hoverEl.offsetHeight + 'px'
	hoverEl.style.left = rect.left + 16 + 'px'

	setTimeout(() => {
		(hoverEl as HTMLElement).classList.add('active')
		window.addEventListener('scroll', hideHoverEl, { once: true })
		needHideHover = false
	}, 50)
}

interface IHoverProps {
	title: string,
	text: string,
}

function createMapHoverEl({ title, text }: IHoverProps, parent: HTMLElement): HTMLElement | void {
	let hoverEl = document.querySelector<HTMLElement>('.map-hover')

	if (hoverEl) {
		const titleEl = hoverEl.querySelector<HTMLElement>('.map-hover__title')
		const textEl = hoverEl.querySelector<HTMLElement>('.map-hover__text')
		const copyBtn = hoverEl.querySelector<HTMLButtonElement>('button[data-action="copy"]')

		if (titleEl && textEl && copyBtn) {
			titleEl.innerText = title
			textEl.innerText = text
			copyBtn.dataset.copy = text
		}

		return hoverEl
	}

	hoverEl = document.createElement('div')
	hoverEl.className = 'map-hover'

	hoverEl.addEventListener('mouseover', () => needHideHover = false)
	hoverEl.addEventListener('mouseout', () => {
		needHideHover = true
		hideHoverEl()
	})

	const copyBtn = document.createElement('button')
	copyBtn.className = 'copy-btn'
	copyBtn.dataset.action = 'copy'
	copyBtn.dataset.copy = text

	copyBtn.addEventListener('click', function (e) {
		const copyData = (e.currentTarget as HTMLButtonElement).dataset.copy
		if (copyData) navigator.clipboard.writeText(copyData)
	})

	hoverEl.innerHTML = `
		<div class="map-hover__title">${ title }</div>
		<div class="map-hover__text">${ text }</div>
	`

	hoverEl.append(copyBtn)
	parent.append(hoverEl)

	hoverEl = document.querySelector<HTMLElement>('.map-hover')

	if (hoverEl) return hoverEl
}