import Swiper from 'npm/swiper'

export default function paginationSet(swiper: Swiper, parentEl: HTMLElement | null) {
	if (!parentEl) return

	const paginationCurrentEl = parentEl.querySelector<HTMLElement>('.pagination__num span:first-child')
	const paginationTotalEl = parentEl.querySelector<HTMLElement>('.pagination__num span:nth-child(2)')

	const currentPageEl = swiper.pagination.el.querySelector<HTMLElement>('.swiper-pagination-current')
	const totalPageEl = swiper.pagination.el.querySelector<HTMLElement>('.swiper-pagination-total')

	if (!currentPageEl || !totalPageEl) return
	if (!paginationCurrentEl || !paginationTotalEl) return

	const currentPageNum = parseInt(currentPageEl.innerText)
	const totalPageNum = parseInt(totalPageEl.innerText)

	paginationCurrentEl.innerText = currentPageNum < 10
	  ? '0' + currentPageNum
	  : currentPageNum.toString()

	paginationTotalEl.innerText = totalPageNum < 10
	  ? '0' + totalPageNum
	  : totalPageNum.toString()
}