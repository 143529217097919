import errors, { MapError } from 'js/features/y-maps/errors'
import { createMapList, IMapItem } from 'js/components/map-block/map-list'

export async function createMapBlockWrapper() {
	createMapBlock()
	  .catch((err: MapError) =>
		console.error('Error while create or loading Y.Map.\n data:', err.data, err),
	  )
}

async function createMapBlock() {
	const mapEl = document.querySelector<HTMLElement>('.map-block__map')
	if (!mapEl) return

	const map = new ymaps.Map(mapEl, {
		center: [ 55.76, 37.64 ],
		zoom: 7,
	})

	// Create placemarks on map
	const listItems = document.querySelectorAll<HTMLElement>('.map-item')
	const coords = getCoordinates(listItems)
	const mapList: IMapItem[] = coords.map((i, index) => ({
		el: listItems[index], placemark: createPlacemark(map, i),
	}))

	// Fit borders of map to the coordinates
	const geoObjectsBounds = map.geoObjects.getBounds()
	if (geoObjectsBounds)
		await map.setBounds(geoObjectsBounds, { checkZoomRange: true, zoomMargin: [ 9 ] })

	createMapList(map, mapList)
}

type Coords = [ number, number ]

function createPlacemark(map: ymaps.Map, coords: Coords): ymaps.Placemark {
	const placemark = new ymaps.Placemark(coords, {})
	map.geoObjects.add(placemark)
	return placemark
}

function getCoordinates(list: NodeListOf<HTMLElement>) {
	const result: Coords[] = []

	list.forEach(i => {
		const rawPos = i.dataset.pos
		if (!rawPos) return

		const match = rawPos.match(/([\d.]+)[, ]+([\d.]+)/)

		if (!match || !match[1] || !match[2]) throw new MapError(errors.dataPosition, i)

		result.push([ +match[1], +match[2] ])
	})

	return result
}