mobileBlogSwiper()
export function mobileBlogSwiper() {
	const blogSwiperWrapper = document.querySelector<HTMLElement>(':is(.blog, .blog_extended) .swiper-wrapper')

	if (!blogSwiperWrapper) return

	const blogSwiper = getBlogSwiper(blogSwiperWrapper)

	window.addEventListener('load', () => {
		if (blogSwiper.isTablet()) blogSwiper.setMobileSwiper()
		else blogSwiper.setDesktopSwiper()
	})

	window.addEventListener('resize', () => {
		if (blogSwiper.isTablet()) blogSwiper.setMobileSwiper()
		else blogSwiper.setDesktopSwiper()
	})
}

interface IBlogSwiper {
	isTablet(): boolean,

	_blogSwiperCache: string,
	_status: 'mobile' | 'desktop' | null,

	setMobileSwiper(): void,

	setDesktopSwiper(): void,
}

function getBlogSwiper(swiperWrapper: HTMLElement): IBlogSwiper {
	return {
		_blogSwiperCache: swiperWrapper.innerHTML,
		isTablet: () => window.matchMedia('(max-width: 1100px)').matches,
		_status: null,

		setMobileSwiper() {
			if (this._status === 'mobile') return

			const blogSliders = swiperWrapper.querySelectorAll(':is(.blog, .blog_extended) .info-card')

			swiperWrapper.innerHTML = ''
			blogSliders.forEach(i => swiperWrapper.append(i))

			this._status = 'mobile'
		},

		setDesktopSwiper() {
			if (this._status === 'desktop') return

			swiperWrapper.innerHTML = this._blogSwiperCache

			this._status = 'desktop'
		},
	}
}