import createPaginationSwiper from 'js/features/swipers/createPaginationSwiper'

createPaginationSwiper('.news', {
	spaceBetween: 25,
	slidesPerView: 1,
	slidesPerGroup: 1,
	breakpoints: {
		600: {
			spaceBetween: 30,
			slidesPerView: 2,
			slidesPerGroup: 2,
		},
		1101: {
			spaceBetween: 40,
			slidesPerView: 3,
			slidesPerGroup: 3,
		}
	}
})