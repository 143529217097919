import createMqSwiper, { IMqSwiperObj } from 'js/features/swipers/createMqSwiper'
import Swiper, { Thumbs, EffectFade } from 'swiper'

createMqSwiper({
	mq: '(max-width: 600px)',
	obj: {
		selector: '.products .tab-switcher__menu-swiper',
		options: {
			spaceBetween: 1,
			slidesPerView: 1,
		},
		callback: callback,
		callbackOnDestroy: destroyCallback,
	},
})

interface IProductsSwiperObj extends IMqSwiperObj {
	additional?: { bodySwiper: Swiper },
}

function callback(ctx: IProductsSwiperObj): void {
	if (!(ctx.swiper instanceof Swiper)) return

	const swiper = new Swiper('.products .tab-switcher__body-swiper', {
		slidesPerView: 1,
		effect: 'fade',
		thumbs: {
			swiper: ctx.swiper,
		},
		fadeEffect: {
			crossFade: true,
		},
		modules: [ Thumbs, EffectFade ],
	})

	ctx.additional = {
		bodySwiper: swiper,
	}
}

function destroyCallback(ctx: IProductsSwiperObj): void {
	if (!ctx.additional) return

	ctx.additional.bodySwiper.destroy()
}