// Swipers
import './swipers/productsSwiper'
import './swipers/catalogSwiper'
import 'js/swipers/blog/blog-swiper'
import 'js/swipers/blog/blog-mobile-swiper'
import 'js/swipers/news/news-swiper'

// Components
import 'js/components/tab-switcher'
import { createMapBlockWrapper } from 'js/components/map-block/map-block'
loadYMap(CONSTANT_VARS.yandexApiKey)
  .then(createMapBlockWrapper)

import 'js/ui/card'
import { loadYMap } from 'js/features/y-maps/loadYMap'
