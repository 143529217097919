const tabSwitchers = document.querySelectorAll<HTMLDivElement>('.tab-switcher')

tabSwitchers.forEach(tabSwitcher => {
	const buttons = tabSwitcher.querySelectorAll<HTMLButtonElement>('.tab-switcher__menu-item')

	const firstTab = tabSwitcher.querySelector('.tab-switcher__menu-item')
	const firstBodyItem = tabSwitcher.querySelector('.tab-switcher__body-item')

	if (!firstTab || !firstBodyItem) return

	firstTab.classList.add('active')
	firstBodyItem.classList.add('active')

	buttons.forEach(btn => {
		btn.addEventListener('click', (e) => tabHandler(e, tabSwitcher))
	})
})

function tabHandler(e: MouseEvent, tabSwitcher: HTMLDivElement): void {
	const target = e.currentTarget as HTMLButtonElement
	const id = target.dataset.tab

	const targetBody = tabSwitcher.querySelector(`.tab-switcher__body-item[data-tab="${ id }"]`)

	const error = new Error(`Tab-switcher doesnt have body-item with id ${ id }`)
	if (!targetBody) throw error

	const activeTab = tabSwitcher.querySelector('.tab-switcher__menu-item.active')
	if (activeTab) activeTab.classList.remove('active')

	target.classList.add('active')

	const activeBody = tabSwitcher.querySelector('.tab-switcher__body-item.active')
	if (activeBody) activeBody.classList.remove('active')

	targetBody.classList.add('active')
}